import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '@scpc/modules/common/components/button/button.component';

@Component({
  selector: 'scp-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, ButtonComponent],
})
export class NoResultsComponent {

  @Input()
  public icon: string;

  @Input()
  public title: string;

  @Input()
  public text: string;

  @Input()
  public width: number;

  @Input()
  public height: number;

  @Input()
  public url: string;

  @Input()
  public urlText: string;

  @Input()
  public invert = true;

}
